import React, { Component } from 'react';

class CapitalHumano extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return (
            <div className="capitalHumano p-4 p-md-5 reveal animate__animated">
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <p className='m-0 text-center' style={{ fontSize: '40px', textTransform: 'uppercase', fontWeight: '200' }}>¿Quienes somos?</p>
                    </div>
                    <div className="col-md-8 col-lg-6 mt-3">
                        <p className='m-0 textoCH'>Somos una empresa mexicana especializada en diseñar y construir obras residenciales y de servicios, contamos con equipos multidisciplinarios enfocados en hacer proyectos a la medida de nuestros clientes.</p>
                        {/* <p className='m-0 textoCH'>Somos una empresa mexicana especializada en diseñar y construir obras residenciales y de servicios, contamos con equipos multidisciplinarios enfocados en hacer proyectos a la medida de nuestros clientes. Utilizamos tecnología de vanguardia para el diseño y construcción de nuestros proyectos, tenemos el firme compromiso de realizar proyectos unicos, personalizados y entregar presupuestos justos y honestos a nuestros clientes.</p> */}
                    </div>
                </div>
            </div>
         );
    }
}
 
export default CapitalHumano;
import React, { Component } from 'react';
import imgCDMX from '../../img/Inicio/cdmx.png';
import imgEDOMEX from '../../img/Inicio/edomex.png';
import imgHGO from '../../img/Inicio/hidalgo.png';
import imgQRO from '../../img/Inicio/queretaro.png';
import imgNL from '../../img/Inicio/NL.png';
import imgGua from '../../img/Inicio/Guanajuato.png';
import imgJal from '../../img/Inicio/Jalisco.png';
import imgSin from '../../img/Inicio/Sinaloa.png';
import imgTab from '../../img/Inicio/Tabaso.png';
import imgZac from '../../img/Inicio/Zacatecas.png';
import { Carousel } from 'react-bootstrap';

class Presencia extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return ( 
            <div className="presencia p-5 justify-content-center align-items-center mx-auto">
                <div className="row justify-content-center align-items-center mx-auto">
                    <div className="col-12 text-center reveal animate__animated">
                        <p className='mb-0' style={{ fontSize: '40px', textTransform: 'uppercase', fontWeight: '200' }}>Tenemos presencia en</p>
                    </div>
                    <div className="col-12 d-md-none">
                        <Carousel controls={false} indicators={false} slide interval={1000}>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgCDMX} alt="" className='imgEstado floating animateDelay0_5s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Ciudad de México</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgEDOMEX} alt="" className='imgEstado floating animateDelay1s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Estado de México</p>
                                    </div>
                                </div>                                
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgHGO} alt="" className='imgEstado floating animateDelay1_5s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Hidalgo</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgQRO} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Querétaro</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgNL} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Nuevo León</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgGua} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Guanajuato</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgJal} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Jalisco</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgSin} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Sinaloa</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgTab} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Tabasco</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <img src={imgZac} alt="" className='imgEstado floating animateDelay2s'/>
                                    </div>
                                    <div className="col-12">
                                        <p className='estado'>Zacatecas</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgCDMX} alt="" className='imgEstado floating animateDelay0_5s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Ciudad de México</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgEDOMEX} alt="" className='imgEstado floating animateDelay1s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Estado de México</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgHGO} alt="" className='imgEstado floating animateDelay1_5s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Hidalgo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgQRO} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Querétaro</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgNL} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Nuevo León</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mx-auto">
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgGua} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Guanajuato</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgJal} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Jalisco</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgSin} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Sinaloa</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgTab} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Tabasco</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-2 d-none d-md-flex align-items-center justify-content-center reveal animate__animated mx-3">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgZac} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Zacatecas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Presencia;
import React, { Component } from 'react';
import imgPred from '../../img/Inicio/inicio_1.jpg';
import imgDiseno from '../../img/Inicio/inicio_diseno.jpg';
import imgConstruccion from '../../img/Inicio/inicio_construccion.jpg';
import imgRemodelacion from '../../img/Inicio/inicio_remodelacion.jpg';
import imgComercial from '../../img/Inicio/inicio_comercial.jpg';

class PrincipalInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="principal">
                <div className="contImg1">
                    <div className='overlay' />
                    <img src={imgPred} alt="" id='img1' className='img1' width="100" height="100" />
                    <img src={imgDiseno} alt="" id='imgDiseno' className='img1 imgDiseno' width="100" height="100" />
                    <img src={imgConstruccion} alt="" id='imgConstruccion' className='img1 imgConstruccion' width="100" height="100" />
                    <img src={imgRemodelacion} alt="" id='imgRemodelacion' className='img1 imgRemodelacion' width="100" height="100" />
                    <img src={imgComercial} alt="" id='imgComerciales' className='img1 imgComerciales' width="100" height="100" />
                </div>
                <div className="contenedoPrincipal justify-content-center text-center align-items-center">
                    <div className='row contenedorServicios'>
                        <div className="elementoServicio ">
                            <p className='text-center'>Garteel</p>
                            <p className='subtitle'>"Especialistas en casas y departamentos residenciales."</p>
                        </div>
                    
                    </div>
                </div>

            </div>
        );
    }
}

export default PrincipalInicio;
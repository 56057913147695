import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import TarjetaPropiedad from './TarjetaPropiedad';
import '../../css/comprar.css';
import { Modal } from 'react-bootstrap';
import selloGarteel from '../../img/selloGarteel.svg'

class Comprar extends React.Component {
    
    constructor(){
        super();
        this.state = {
            propiedades: [],
            propiedadesFiltradas: [],
            filtroPrecio: 'Asc',
            filtroTipoPropiedad: '',
            filtroRecamaras: '',
            disableFiltroRecamaras: false,
            disableFiltroTipoPropiedad: false,
            showModal: false
        }
    }

    componentDidMount = async () => {
        document.title = 'Comprar - Garteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
          };
          const response = await fetch('https://www.garteel.com/php/DB/propiedades.php', requestOptions);
          const data = await response.json();
          this.setState({
            propiedades: data
        })
        this.filtrar();
    }

    onChangeValueFiltroPrecio = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.filtrar();
    }

    onChangeValueFiltroTipoPropiedad = async (e) => {
        if (e.target.value === 'Casa' || e.target.value === 'Departamento' || e.target.value === '') {
            await this.setState({
                [e.target.name]: e.target.value,
                disableFiltroRecamaras: false
            })
            this.filtrar();
        }else{
            await this.setState({
                [e.target.name]: e.target.value,
                filtroRecamaras: '',
                disableFiltroRecamaras: true
            })
            this.filtrar();
        }
    }

    onChangeValueFiltroRecamaras = async (e) => {
        if (e.target.value === '') {
            await this.setState({
                [e.target.name]: e.target.value,
                disableFiltroTipoPropiedad: false
            })
            this.filtrar();
        }else{
            if (e.target.value !== '' || this.state.filtroTipoPropiedad !== '') {
                await this.setState({
                    [e.target.name]: e.target.value,
                    disableFiltroTipoPropiedad: true
                })
                this.filtrar();
            }
        }
    }

    ordernarAsc = (data) => {
        return data.sort(function (a, b) {
            if (Number(a.precio) > Number(b.precio)) {
              return 1;
            }
            if (Number(a.precio) < Number(b.precio)) {
              return -1;
            }
            return 0;
          });
    }

    ordernarDesc = (data) => {
        return data.sort(function (a, b) {
            if (Number(a.precio) < Number(b.precio)) {
              return 1;
            }
            if (Number(a.precio) > Number(b.precio)) {
              return -1;
            }
            return 0;
          });
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        })
    }

    filtrar = () => {
        var propiedadesFiltradas = this.state.propiedades;
        if (this.state.filtroRecamaras !== '') {
            if (Number(this.state.filtroRecamaras) < 5) {
                propiedadesFiltradas = propiedadesFiltradas.filter(propiedad => propiedad.recamaras === this.state.filtroRecamaras);
            }else{
                propiedadesFiltradas = propiedadesFiltradas.filter(propiedad => Number(propiedad.recamaras) >= Number(this.state.filtroRecamaras));
            }
        }
        if (this.state.filtroTipoPropiedad !== '') {
            propiedadesFiltradas = propiedadesFiltradas.filter(propiedad => propiedad.tipoPropiedad === this.state.filtroTipoPropiedad);
        }
        if (this.state.filtroPrecio === 'Asc') {
            propiedadesFiltradas = this.ordernarAsc(propiedadesFiltradas);
        }
        if (this.state.filtroPrecio === 'Desc') {
            propiedadesFiltradas = this.ordernarDesc(propiedadesFiltradas);
        }
        this.setState({
            propiedadesFiltradas: propiedadesFiltradas
        })
    }

    render() { 
        return (
            <>
                <NavBar/>
            
                <div className="container-fluid my-4 pb-4 comprar">
                    <div className="text-center">
                        <h1 style={{ color: '#2EBF70' }}>ESTÁS CERCA DE TU PROPIEDAD IDEAL</h1>
                        <h4 className='text-white'>Nuestro equipo de asesores te ayudará a encontrarla.</h4>
                    </div>
                    <hr className='text-white'/>
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-lg-8 col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-11 col-md-8 mb-3 mb-lg-0 d-lg-none">
                                    <div className='p-2' style={{ backgroundColor: '#2EBF70', borderRadius: '5px', height: '100%' }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-3 col-sm-2 col-md-2 pe-0 text-end">
                                                <img src={selloGarteel} alt="selloGarteel" style={{ width: '100%' }}/>
                                            </div>
                                            <div className="col-9 col-sm-10">
                                                <div className="row mt-1">
                                                    <div className="col-12 text-center">
                                                        <p className='m-0' style={{ color: 'white', fontSize: '14px' }}>Inmuebles revisados, construidos y supervisados por expertos.</p>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 mb-0">
                                                    <div className="col-12 text-center">
                                                        <button type='button' className='btn btn-sm btn-outline-light w-50' onClick={this.showModal}>Más Info.</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3 mb-xl-0 d-flex justify-content-center align-items-center">
                                    <label className='text-white'>Precio</label>
                                    <select name="filtroPrecio" className='form-control filtroPrecio ms-3 text-center' value={this.state.filtroPrecio} onChange={this.onChangeValueFiltroPrecio}>
                                        <option value="Asc">Menor a Mayor</option>
                                        <option value="Desc">Mayor a Menor</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3 mb-xl-0 d-flex justify-content-center align-items-center">
                                    <label className='text-white'>Tipo de Propiedad</label>
                                        <select name="filtroTipoPropiedad" className='form-control filtroPrecio ms-3 text-center' value={this.state.filtroTipoPropiedad} onChange={this.onChangeValueFiltroTipoPropiedad}>
                                            <option value="">Sin filtro</option>
                                            <option value="Casa">Casa</option>
                                            <option value="Comercial" disabled={this.state.disableFiltroTipoPropiedad}>Comercial</option>
                                            <option value="Departamento">Departamento</option>
                                            <option value="Industrial" disabled={this.state.disableFiltroTipoPropiedad}>Industrial</option>
                                            <option value="Terreno" disabled={this.state.disableFiltroTipoPropiedad}>Terreno</option>
                                        </select>
                                </div>
                                <div className="col-sm-7 col-md-6 col-lg-7 col-xl-5 d-flex justify-content-center align-items-center">
                                    <label className='text-white'>Número de Recámaras</label>
                                        <select name="filtroRecamaras" className='form-control filtroPrecio ms-3 text-center' value={this.state.filtroRecamaras} onChange={this.onChangeValueFiltroRecamaras} disabled={this.state.disableFiltroRecamaras}>
                                            <option value="">Sin filtro</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5 o más</option>
                                        </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-4">
                            <div className="row">
                                <div className="col-12 d-none d-lg-block">
                                    <div className='p-2' style={{ backgroundColor: '#2EBF70', borderRadius: '5px', height: '100%' }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-2 pe-0 text-end">
                                                <img src={selloGarteel} alt="selloGarteel" style={{ width: '100%' }}/>
                                            </div>
                                            <div className="col-10">
                                                <div className="row mt-1">
                                                    <div className="col-12 text-center">
                                                        <p className='m-0' style={{ color: 'white', fontSize: '14px' }}>Inmuebles revisados, construidos y supervisados por expertos.</p>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 mb-0">
                                                    <div className="col-12 text-center">
                                                        <button type='button' className='btn btn-sm btn-outline-light w-755' onClick={this.showModal}>Más Info.</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row text-center">
                        {
                            this.state.propiedadesFiltradas.map((e, index) => <TarjetaPropiedad key={index} data={e}/>)
                        }
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={this.hideModal} centered>
                    <Modal.Header className='d-flex justify-content-center'>
                        <h3 className='m-0' style={{ color: '#2EBF70' }}>Calidad Garteel</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Son inmuebles que otorgan el mayor costo-benefício para tu inversión, con un proceso constructivo de calidad, supervisado en todo momento por arquitectos especializados.</p>
                        <p>Aprovecha los beneficios que un inmueble Garteel te otorga:</p>
                        <ul className='listaBeneficios'>
                            <li>Propiedades ubicadas en zonas con plusvalía.</li>
                            <li>Sin problemas legales.</li>
                            <li>Acabados de primera, superiores a los del mercado.</li>
                            <li>Mayor costo-benefício.</li>
                            <li>Sin daños estructurales.</li>
                            <li>Con garantía por vicios ocultos durante 6 meses.</li>
                        </ul>
                    </Modal.Body>
                </Modal>

                <WhatsappBtn/>
                <Footer/>
            </>
        );
    }
}
 
export default Comprar;
import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import ImageGallery from 'react-image-gallery';
import {Button} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Modal, Box, Typography, Fade, Backdrop } from '@mui/material';
import selloGarteel from '../../img/selloGarteel.svg';
import '../../css/propiedad.css'

class Propiedad extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            showModal: false,
            nombre: '',
            numTel: '',
            correo: '',
            formSent: false
        }
    }

    cerrarModal = () => {
        this.setState({
            showModal: false,
            formSent: false,
            nombre: '',
            numTel: '',
            correo: '',
        });
    } 

    abrirModal = () => {
        this.setState({
            showModal: true
        })
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idPropiedad: this.props.idPropiedad })
          };
          const response = await fetch('https://www.garteel.com/php/DB/propiedades.php', requestOptions);
          const data = await response.json();
          this.setState({
              data: data[0],
          })
          document.title = this.state.data.nombre + ' - Garteel';
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    submitModal = async (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'enviarCorreo',
                idPropiedad: this.state.data.idPropiedad,
                nombrePropiedad: this.state.data.nombre,
                ubicacion: this.state.data.ubicacion,
                nombreCliente: this.state.nombre,
                numTelCliente: this.state.numTel,
                correoCliente: this.state.correo
            })
        };
        const response = await fetch('https://www.garteel.com/php/propiedadInteres.php', requestOptions);
        const data = await response.json();
        if (data === 'Email Enviado') {
            this.setState({
                formSent: true,
                nombre: '',
                numTel: '',
                correo: '',
            })
        }else{
            alert('Hubo un error, por favor contáctanos por Whatsapp');
        }     
    }

    theme = createTheme({
        palette: {
          success: {
            main: '#2EBF70',
            contrastText: '#ffffff'
          }
        },
      });

    styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #2EBF70',
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      };

    render() { 
        return ( 
            <>
                <NavBar/>

                <div className="container-fluid my-4 propiedad text-white">
                    <div className="row">
                        <div className="col-lg-5 divisor">
                            <div className="row">
                                <div className="col-12">
                                    {this.state.data.imgs &&
                                        <ImageGallery items={this.state.data.imgs} useBrowserFullscreen={false} showPlayButton={false}/>
                                    }
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className="col-12 text-center">
                                    <ThemeProvider theme={this.theme}>
                                        <Button style={{ width: '50%' }} color='success' variant='contained' onClick={this.abrirModal}>me interesa</Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-12 mt-5 mt-lg-0 text-center d-flex justify-content-center align-items-center">
                                    <img src={selloGarteel} alt="" className='selloGarteel me-3'/>
                                    <h1 className='p-0 m-0'>{this.state.data.nombre}</h1>
                                </div>
                            </div>
                            <hr style={{color: '#2EBF70', height: '2px'}}/>
                            <div className="row">
                                <div className="col-6 text-center align-self-center divisor">
                                    <h2>{'$ ' + this.numberWithCommas(Number(this.state.data.precio))}</h2>
                                </div>
                                <div className="col-6 text-center align-self-center">
                                    <h5>Estatus: {this.state.data.estatus}</h5>
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#2EBF70', height: '2px'}}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h5>{this.state.data.ubicacion}</h5>
                                    <hr style={{color: '#2EBF70', height: '2px'}}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 px-4">
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bed" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"></path>
                                                <circle cx="7" cy="10" r="1"></circle>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>Recámaras: {this.state.data.recamaras}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bath" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 12h16a1 1 0 0 1 1 1v3a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-3a1 1 0 0 1 1 -1z"></path>
                                                <path d="M6 12v-7a2 2 0 0 1 2 -2h3v2.25"></path>
                                                <path d="M4 21l1 -1.5"></path>
                                                <path d="M20 21l-1 -1.5"></path>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>Baños Completos: {this.state.data.banosCompletos}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 px-4">
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-toilet-paper" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <ellipse cx="6" cy="10" rx="3" ry="7"></ellipse>
                                                <path d="M21 10c0 -3.866 -1.343 -7 -3 -7"></path>
                                                <path d="M6 3h12"></path>
                                                <path d="M21 10v10l-3 -1l-3 2l-3 -3l-3 2v-10"></path>
                                                <path d="M6 10h.01"></path>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>Baños Medios: {this.state.data.banosMedios}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-car" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx="7" cy="17" r="2"></circle>
                                                <circle cx="17" cy="17" r="2"></circle>
                                                <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>Espacios en Garage: {this.state.data.garage}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 px-4">
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 5h11"></path>
                                                <path d="M12 7l2 -2l-2 -2"></path>
                                                <path d="M5 3l-2 2l2 2"></path>
                                                <path d="M19 10v11"></path>
                                                <path d="M17 19l2 2l2 -2"></path>
                                                <path d="M21 12l-2 -2l-2 2"></path>
                                                <rect x="3" y="10" width="11" height="11" rx="2"></rect>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>M<sup>2</sup> Terreno: {this.state.data.metrosCuadrados}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                                            </svg>
                                            <label style={{ paddingLeft: '10px' }}>M<sup>2</sup> Construidos: {this.state.data.metrosConstruidos}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#2EBF70', height: '2px'}}/>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.data.amenidades && this.state.data.amenidades.map((e, index) => 
                                    <div key={index} className="col-4 col-sm-2 text-center px-0 px-md-2 mb-2">
                                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                            <div style={{ backgroundColor: '#2ebf6f2d', paddingTop: '15px', borderRadius: '10px'  }}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src={'https://www.garteel.com/img/amenidades/' + e.img} alt="" width={'40%'}/>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <label style={{height: '50px', color: 'gray', fontSize: 14 }}>{e.nombre}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            <hr style={{color: '#2EBF70', height: '2px'}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 divisor" style={{ textAlign: 'justify' }}>
                            <div className='px-3'>
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <h5>Descripción</h5>
                                        <hr style={{color: '#2EBF70', height: '2px'}} className='d-none d-md-block'/>
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.data.descripcion }}/>
                            </div>
                            <hr style={{color: '#2EBF70', height: '2px'}} className='d-md-none'/>
                        </div>
                        <div className="col-md-6">
                            <div className='px-3'>
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <h5>Mapa</h5>
                                        <hr style={{color: '#2EBF70', height: '2px'}} className='d-none d-md-block'/>
                                    </div>
                                </div>
                                <div className='mapaPropiedad' dangerouslySetInnerHTML={{ __html: this.state.data.linkMapa }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.showModal}
                    onClose={this.cerrarModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModal}>
                        <Box sx={this.styleModal}>
                            {this.state.formSent === false ? 
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #2EBF70' }}>
                                        ¡Genial! Apóyanos con unos datos
                                    </Typography>
                                    <form action="" onSubmit={this.submitModal}>
                                        <label>Nombre</label>
                                            <input type="text" required className='form-control mb-1' name='nombre' value={this.state.nombre} onChange={this.onChangeValue}/>
                                        <label>Número Telefónico</label>
                                            <input type="tel" required pattern="[0-9]{10,15}" className='form-control mb-1' name='numTel' value={this.state.numTel} onChange={this.onChangeValue}/>
                                        <label>Correo Electrónico</label>
                                            <input type="email" className='form-control mb-3' name='correo' value={this.state.correo} onChange={this.onChangeValue}/>
                                        <ThemeProvider theme={this.theme}>
                                            <Button fullWidth color='success' variant='contained' type='submit'>Enviar</Button>
                                        </ThemeProvider>
                                    </form>
                                </>
                                :
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #2EBF70' }}>
                                        ¡Listo!
                                    </Typography>
                                    <Typography variant='p' component='p' className='text-center d-flex'>
                                        Recibimos tu información y nos pondremos en contacto contigo lo antes posible.
                                    </Typography>
                                </>
                            }
                        </Box>
                    </Fade>
                </Modal>

                <WhatsappBtn/>
                <Footer/>
            </>
         );
    }
}
 
export default Propiedad;
import React from 'react';
import { Navbar, Container, Nav, Button, Offcanvas } from 'react-bootstrap';
import logoBlanco from '../img/logoBlanco.png';
import '../css/navbar.css';
import logoBlancoAGC from '../img/logoBlancoAGC.svg';

class NavBar extends React.Component {

    constructor(){
        super();
        this.state = {
            show : false,
        }
    }

    componentDidMount = () => {
        
    }

    handleShow = () =>{
        this.setState({
            show: true
        })
    }

    handleClose = () =>{
        this.setState({
            show:false
        })
    }

    prueba = () => {
        console.log("escondido")
    }

    render() { 
        return (
            <>
                <Navbar expand="sm" expanded={false}>
                    <Container fluid>
                        <Navbar.Brand href="/">
                        <img
                            src={logoBlanco}
                            height="60"
                            className="d-inline-block align-top animate__animated animate__fadeInLeft"
                            alt="Logo Garteel"
                        />
                        <div className="row">
                            <div className="col-12 text-end">
                                <p className='m-0 text-white' style={{ fontSize: '12px' }}>by Grupo AGC</p>
                            </div>
                        </div>
                        </Navbar.Brand>
                        <Navbar.Collapse>
                                <Nav className='ms-auto'>
                                    <Nav.Link className={window.location.pathname ===  '/' ? 'activo' : ''} href="/">Inicio</Nav.Link>
                                    <Nav.Link className={window.location.pathname ===  '/portafolio' ? 'activo' : ''} href="/portafolio">Portafolio</Nav.Link>
                                </Nav>
                        </Navbar.Collapse>
                        <Button variant="" onClick={this.handleShow} className="me-2 border-0">
                            <i style={{ fontSize: '30px', color: '#2EBF70' }} className="bi bi-list"></i>
                        </Button>
                        <Offcanvas show={this.state.show} onHide={this.handleClose} placement="end">
                            <Offcanvas.Header closeButton closeVariant='white' style={{ border: 'none' }}>
                                <a href="/" style={{ width: '40%', marginLeft: '30%', marginRight: 'auto' }}>
                                    <img
                                        src={logoBlanco}
                                        height="40"
                                        className="logoBlancoMenu animate__animated animate__fadeIn"
                                        alt="Logo Blanco"
                                    />
                                </a>
                            </Offcanvas.Header>
                            <Offcanvas.Body className='pt-0'>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/portafolio' ? 'activo' : '')} style={{ animationDelay: '0s' }} href="/portafolio">Portafolio</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/comprar' ? 'activo' : '')} style={{ animationDelay: '0.1s' }} href="/comprar">Comprar</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/vender' ? 'activo' : '')} style={{ animationDelay: '0.2s' }} href="/vender">Vender</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/invertir' ? 'activo' : '')} style={{ animationDelay: '0.3s' }} href="/invertir">Invertir</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/construir' ? 'activo' : '')} style={{ animationDelay: '0.4s' }} href="/construir">Construir</Nav.Link>
                                <Nav.Link className='my-3 linkOffCanvas animate__animated animate__fadeInDown' style={{ animationDelay: '0.5s' }} href="https://inversionistas.garteel.com">Portal Inversionistas</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/contacto' ? 'activo' : '')} style={{ animationDelay: '0.6s' }} href="/contacto">Contacto</Nav.Link>
                                <ul className="list-unstyled rrssOffcanvas animate__animated animate__fadeIn" style={{ animationDelay: '0.8s' }}>
                                    <li><a className="link-light mx-2" href="https://www.facebook.com/Garteel.Inmobiliaria" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                                    <li><a className='link-light mx-2' href="tel:+527714733271"><i className="bi bi-telephone-fill"></i></a></li>
                                    <li><a className='link-light mx-2' href="https://wa.me/527714733271" target="_blank" rel="noopener noreferrer"><i className="bi bi-whatsapp"></i></a></li>
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
            </>
        );
    }
}
 
export default NavBar;
import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import intCalidos from '../../../img/FormularioPN/intCalidos.jpg';
import intFrios from '../../../img/FormularioPN/intFrios.jpg';
import intNeutros from '../../../img/FormularioPN/intNeutros.jpg';
import faContemporaneo from '../../../img/FormularioPN/faContemporaneo.jpg';
import faIndustrial from '../../../img/FormularioPN/faIndustrial.jpg';
import faMinimalista from '../../../img/FormularioPN/faMinimalista.jpg';
import faRustico from '../../../img/FormularioPN/faRustico.jpg';
import intContemporaneo from '../../../img/FormularioPN/intContemporaneo.jpg';
import intIndustrial from '../../../img/FormularioPN/intIndustrial.jpg';
import intMinimalista from '../../../img/FormularioPN/intMinimalista.jpg';
import intRustico from '../../../img/FormularioPN/intRustico.jpg';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

class Diseno extends React.Component {
    
    constructor(){
        super();
        this.state = {
            caracteristicas : []
        }
    }

    Previous = e => {
        e.preventDefault();
        this.props.prevStep();
      }

    Continue = async (e) => {
    e.preventDefault();
    this.props.nextStep();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operacion: 'enviarCorreo', data: this.props.values })
        };
        const response = await fetch('https://www.garteel.com/php/formularioNP.php', requestOptions);
        const data = await response.json();
        console.log(data);
    }

    onChangeValueCaracteristicas = async (e) => {
        const isChecked = e.target.checked;
        if(isChecked){
            await this.setState({ caracteristicas: [...this.state.caracteristicas, e.target.value] })
        }else{
            const index = this.state.caracteristicas.indexOf(e.target.value);
            this.state.caracteristicas.splice(index, 1);
            await this.setState({ caracteristicas: this.state.caracteristicas });
        }
        this.props.onChangeCaracteristicasDiseno(this.state.caracteristicas);
    }

    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 4}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>Diseño</h3>
                                </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <label><strong>Características</strong> </label>
                                <div className="form-control">
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Aire acondicionado"  onChange={this.onChangeValueCaracteristicas}/> Aire acondicionado</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Alacena grande"  onChange={this.onChangeValueCaracteristicas}/> Alacena grande</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Alberca"  onChange={this.onChangeValueCaracteristicas}/> Alberca</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Asador de carne"  onChange={this.onChangeValueCaracteristicas}/> Asador de carne</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Bar"  onChange={this.onChangeValueCaracteristicas}/> Bar</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Baño compartido en recámaras secundarias"  onChange={this.onChangeValueCaracteristicas}/> Baño compartido en recámaras secundarias</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Baño completo en planta baja"  onChange={this.onChangeValueCaracteristicas}/> Baño completo en planta baja</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Baños completos en todas las recámaras"  onChange={this.onChangeValueCaracteristicas}/> Baños completos en todas las recámaras</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Biblioteca"  onChange={this.onChangeValueCaracteristicas}/> Biblioteca</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Bodega"  onChange={this.onChangeValueCaracteristicas}/> Bodega</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Calentador solar"  onChange={this.onChangeValueCaracteristicas}/> Calentador solar</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Captación de agua pluvial"  onChange={this.onChangeValueCaracteristicas}/> Captación de agua pluvial</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Chimenea"  onChange={this.onChangeValueCaracteristicas}/> Chimenea</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Circuito cerrado"  onChange={this.onChangeValueCaracteristicas}/> Circuito cerrado</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Conexiones inteligentes"  onChange={this.onChangeValueCaracteristicas}/> Conexiones inteligentes</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Cuarto de TV"  onChange={this.onChangeValueCaracteristicas}/> Cuarto de TV</li>
                                    
                                    
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 mt-4">
                                <div className="form-control">
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Cuarto de visitas"  onChange={this.onChangeValueCaracteristicas}/> Cuarto de visitas</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Cuarto para servidumbre de planta"  onChange={this.onChangeValueCaracteristicas}/> Cuarto para servidumbre de planta</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Estudio"  onChange={this.onChangeValueCaracteristicas}/> Estudio</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Gimnasio"  onChange={this.onChangeValueCaracteristicas}/> Gimnasio</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Iluminación inteligente"  onChange={this.onChangeValueCaracteristicas}/> Iluminación inteligente</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Jacuzzi"  onChange={this.onChangeValueCaracteristicas}/> Jacuzzi</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Jardín amplio"  onChange={this.onChangeValueCaracteristicas}/> Jardín amplio</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Otro"  onChange={this.onChangeValueCaracteristicas}/> Otro</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Paneles solares"  onChange={this.onChangeValueCaracteristicas}/> Paneles solares</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Persianas eléctricas"  onChange={this.onChangeValueCaracteristicas}/> Persianas eléctricas</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Recamara en planta baja"  onChange={this.onChangeValueCaracteristicas}/> Recamara en planta baja</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Roof Top"  onChange={this.onChangeValueCaracteristicas}/> Roof Top</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Sauna"  onChange={this.onChangeValueCaracteristicas}/> Sauna</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Sistema de Audio"  onChange={this.onChangeValueCaracteristicas}/> Sistema de Audio</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Terrazas o balcones"  onChange={this.onChangeValueCaracteristicas}/> Terrazas o balcones</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Vapor"  onChange={this.onChangeValueCaracteristicas}/> Vapor</li>
                                    <li style={{listStyle: 'none'}}><input type="checkbox" value="Vestidores en todas las recamaras"  onChange={this.onChangeValueCaracteristicas}/> Vestidores en todas las recamaras</li>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3 ">
                            <div className="col-12 col-md-7 col-lg-6">
                                <label>¿Qué colores te gustaría que tenga tu hogar?</label> <label style={{ color: '#9e9e9e' }}>Click para hacer Zoom</label>
                                <div className="row text-center">
                                    <div className="col-4">
                                        <Zoom>
                                            <img src={intFrios} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Fríos</label>
                                        </div>
                                        <input type="radio" name="color" id="color" value={'Frios'} onChange={this.props.handleChange('color')}/>
                                    </div>
                                    <div className="col-4">
                                        <Zoom>
                                            <img src={intNeutros} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Neutros</label>
                                        </div>
                                        <input type="radio" name="color" id="color" value={'Neutros'} onChange={this.props.handleChange('color')}/>
                                    </div>
                                    <div className="col-4">
                                        <Zoom>
                                            <img src={intCalidos} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Cálidos</label>
                                        </div>
                                        <input type="radio" name="color" id="color" value={'Calidos'} onChange={this.props.handleChange('color')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12">
                                <label>¿Qué estilo te gustaría que tuviera tu hogar?</label> <label style={{ color: '#9e9e9e' }}>Click para hacer Zoom</label>
                                <div className="row text-center">
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={faContemporaneo} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Contemporáneo</label>
                                        </div>
                                        <input type="radio" name="fachada" id="fachada" value={'Contemporaneo'} onChange={this.props.handleChange('fachada')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={faIndustrial} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Industrial</label>
                                        </div>
                                        <input type="radio" name="fachada" id="fachada" value={'Industrial'} onChange={this.props.handleChange('fachada')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={faMinimalista} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Minimalista</label>
                                        </div>
                                        <input type="radio" name="fachada" id="fachada" value={'Minimalista'} onChange={this.props.handleChange('fachada')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={faRustico} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Rústico</label>
                                        </div>
                                        <input type="radio" name="fachada" id="fachada" value={'Rustico'} onChange={this.props.handleChange('fachada')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12">
                                <label>¿Cómo te gustaría que sean los interiores en tu hogar?</label> <label style={{ color: '#9e9e9e' }}>Click para hacer Zoom</label>
                                <div className="row text-center">
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={intContemporaneo} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Contemporáneo</label>
                                        </div>
                                        <input type="radio" name="interiores" id="interiores" value={'Contemporaneo'} onChange={this.props.handleChange('interiores')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={intIndustrial} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Industrial</label>
                                        </div>
                                        <input type="radio" name="interiores" id="interiores" value={'Industrial'} onChange={this.props.handleChange('interiores')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={intMinimalista} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Minimalista</label>
                                        </div>
                                        <input type="radio" name="interiores" id="interiores" value={'Minimalista'} onChange={this.props.handleChange('interiores')}/>
                                    </div>
                                    <div className="col-3">
                                        <Zoom>
                                            <img src={intRustico} alt="" style={{maxWidth : '100%', maxHeight: '100px'}}/>
                                        </Zoom>
                                        <div className="row">
                                            <label>Rústico</label>
                                        </div>
                                        <input type="radio" name="interiores" id="interiores" value={'Rustico'} onChange={this.props.handleChange('interiores')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Previous}>Anterior</Button>
                                </ThemeProvider>
                            </div>
                            <div className="col-6 text-end">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained'  onClick={this.Continue}>Finalizar</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Diseno;